import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Marquee from "react-fast-marquee";

const valuableInsights = ({
	heading,
	content,
	btnCta1,
	btnCta2,
	bgImage,
	bgColor,
	textColor = "#fff",
	trustedByHeading,
	truestedByLogos,
}) => {
	return (
		<section className="py-5 py-md-8" style={sectionStyle(bgImage, bgColor)}>
			<Container>
				{heading && (
					<Row className="align-items-lg-center justify-content-lg-center">
						<Col lg={7} className="mb-4 mb-lg-0 text-center">
							<h2 className="my-0" style={{ color: textColor }}>
								{heading}
							</h2>
							<div
								className="py-4"
								style={{ color: textColor }}
								dangerouslySetInnerHTML={{ __html: content }}
							></div>
						</Col>
					</Row>
				)}

				<div className="d-flex justify-content-center mt-lg-5">
					<Button
						as={Link}
						href={btnCta1.url}
						size="lg"
						className="px-4 py-3 montserrat-bold fs-6 me-3"
						target={btnCta1.target}
						variant="secondary"
					>
						{btnCta1.title}
					</Button>
					{btnCta2 && btnCta2.url && (
						<Button
							as={Link}
							href={btnCta2.url}
							size="lg"
							className="px-4 py-3 montserrat-bold fs-6 border-white text-white bg-transparent"
							target={btnCta2.target}
						>
							{btnCta2.title}
						</Button>
					)}
				</div>
				<Row className="align-items-lg-center pt-5 pt-md-8">
					<Col
						xxl={2}
						lg={3}
						className="mb-4 mb-lg-0  text-center  text-md-start"
					>
						<h3 className="my-auto text-white pb-2">{trustedByHeading}</h3>
					</Col>
					{truestedByLogos && truestedByLogos.length > 0 && (
						<Col xxl={10} lg={9}>
							<Marquee>
								{truestedByLogos.map((item, i) => (
									<div
										className="bg-white rounded me-5 px-2"
										style={{ height: "85px", width: "152px" }}
									>
										<GatsbyImage
											image={
												item.featureImage?.featureImage.node?.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={item.featureImage?.featureImage.node?.altText}
											key={i}
											className="me-5 bg-white rounded"
											style={{
												width: "100%",
												height: "100%",
											}}
											imgClassName="object-fit-contain"
										/>
									</div>
								))}
							</Marquee>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export const sectionStyle = (bgImage, bgColor) => {
	return {
		background: `linear-gradient(0deg, ${bgColor} 0%, ${bgColor} 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
	};
};

export default valuableInsights;
