import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import ArticleListing from "../components/article-listing";
import ClientTestimonials from "../components/client-testimonials";
import Layout from "../components/layout";
import NczAnnouncements from "../components/ncz-announcement";
import StayUpToDate from "../components/stay-up-to-date";
import ValuableInsights from "../components/valuable-insight";

const InsightAndGuidePage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "insights-and-guides" }) {
				insightsGuides {
					nczAnnouncements {
						nczAnnouncementsHeading
						nczAnnouncementsSubHeading
						nczAnnouncementsContent
						backgroundColor
					}
					industryInsights {
						industryInsightsHeading
						industryInsightsSubHeading
						industryInsightsContent
					}
					insightsAndGuidesTestimonials {
						backgroundColor
						selectTestimonials {
							nodes {
								... on WpReview {
									title
									date(formatString: "DD MMMM, YYYY")
									reviewPostFields {
										reviewAuthor
										reviewAuthorDesignation
										reviewAuthorCompanyPicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 80
														)
													}
												}
											}
										}
										reviewQuote
										reviewStarSelect
										reviewAuthorProfilePicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 56
														)
													}
												}
											}
										}
									}
									terms {
										nodes {
											taxonomyName
											name
										}
									}
								}
							}
						}
					}
					nczGuide {
						nczGuideHeading
						nczGuideSubHeading
						nczGuideContent
					}
					stayUpToDateSection {
						stayUpToDateSectionHeading
						stayUpToDateSectionContent
						sectionImageBackgroundColor
						stayUpToDateSectionNotice
						stayUpToDateSectionImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					valuableInsights {
						valuableInsightsContent
						valuableInsightsHeading
						backgroundColor
						backgroundImage {
							node {
								altText
								sourceUrl
							}
						}
						trustedByHeading
						selectTrustedByLogos {
							nodes {
								... on WpTrustedByLogo {
									featureImage {
										featureImage {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
						valuableInsightsButton2 {
							title
							url
							target
						}
						valuableInsightsButton1 {
							title
							url
							target
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
			announcementPost: allWpPost(
				filter: {
					blogPostFields: {
						blogPostBanner: { category: { in: ["Blog", "Guide"] } }
					}
				}

				sort: { order: DESC, fields: date }
			) {
				nodes {
					slug
					blogPostFields {
						blogPostBanner {
							category
							excerpt
							blogPostBannerHeading
							blogPostBannerImage {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							# blogPostBannerAuthorProfileImage {
							# 	node {
							# 		altText
							# 		sourceUrl
							# 		localFile {
							# 			childImageSharp {
							# 				gatsbyImageData(
							# 					formats: WEBP
							# 					quality: 100
							# 					transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							# 					layout: CONSTRAINED
							# 					placeholder: BLURRED
							# 					width: 56
							# 				)
							# 			}
							# 		}
							# 	}
							# }
						}
						blogPostContentEditor {
							blogPostDefaultContent
						}
					}
					date(formatString: "DD MMMM, YYYY")
					title
					excerpt
					featureImage {
						featureImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					terms {
						nodes {
							slug
							name
							... on WpCategory {
								name
								parentId
								slug
							}
						}
					}
				}
			}
			industryInsightPosts: allWpPost(
				filter: {
					blogPostFields: { blogPostBanner: { category: { eq: "Newsletter" } } }
				}

				sort: { order: DESC, fields: date }
			) {
				nodes {
					slug
					blogPostFields {
						blogPostBanner {
							category
							excerpt
							blogPostBannerHeading
							blogPostBannerImage {
								node {
									altText
									sourceUrl
								}
							}
							# blogPostBannerAuthorProfileImage {
							# 	node {
							# 		altText
							# 		sourceUrl
							# 	}
							# }
						}
						blogPostContentEditor {
							blogPostDefaultContent
						}
					}
					date(formatString: "DD MMMM, YYYY")
					title
					excerpt
					featureImage {
						featureImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					terms {
						nodes {
							slug
							name
							... on WpCategory {
								name
								parentId
								slug
							}
						}
					}
				}
			}
			# nczGuidePosts: allWpPost(
			# 	filter: {
			# 		blogPostFields: {
			# 			blogPostBanner: { category: { eq: "Guide" } }
			# 		}
			# 	}
			# 	filter: {
			# 		terms: { nodes: { elemMatch: { slug: { eq: "ncz-guide" } } } }
			# 	}

			# 	sort: { order: DESC, fields: date }
			# ) {
			# 	nodes {
			# 		slug
			# 		excerpt
			# 		blogPostFields {
			# 			blogPostBanner {
			# 				category
			# 				blogPostBannerAuthorName
			# 				blogPostBannerHeading
			# 				blogPostBannerImage {
			# 					node {
			# 						altText
			# 						sourceUrl
			# 					}
			# 				}
			# 				blogPostBannerAuthorProfileImage {
			# 					node {
			# 						altText
			# 						sourceUrl
			# 					}
			# 				}
			# 			}
			# 			blogPostContentEditor {
			# 				blogPostDefaultContent
			# 			}
			# 		}
			# 		date(formatString: "DD MMMM, YYYY")
			# 		title
			# 		featureImage {
			# 			featureImage {
			# 				node {
			# 					altText
			# 					sourceUrl
			# 					localFile {
			# 						childImageSharp {
			# 							gatsbyImageData(
			# 								formats: WEBP
			# 								quality: 100
			# 								transformOptions: { cropFocus: CENTER, fit: COVER }
			# 								layout: CONSTRAINED
			# 								placeholder: BLURRED
			# 							)
			# 						}
			# 					}
			# 				}
			# 			}
			# 		}
			# 		terms {
			# 			nodes {
			# 				slug
			# 				name
			# 				... on WpCategory {
			# 					name
			# 					parentId
			# 					slug
			# 				}
			# 			}
			# 		}
			# 	}
			# }
			# allAnnouncementCategory: allWpCategory(
			# 	filter: { parentDatabaseId: { eq: 53 } }
			# ) {
			# 	nodes {
			# 		name
			# 		slug
			# 	}
			# }
		}
	`);
	const allAnnouncementCategory = [
		{ name: "Blogs", slug: "Blog" },
		{ name: "Guides", slug: "Guide" },
	];
	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, insightsGuides },

		announcementPost,
		industryInsightPosts,
		nczGuidePosts,
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Insights",
				item: {
					url: `${siteUrl}/insights`,
					id: `${siteUrl}/insights`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/insights`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout isDefault={false}>
				<NczAnnouncements
					heading={insightsGuides?.nczAnnouncements.nczAnnouncementsHeading}
					tagline={insightsGuides?.nczAnnouncements.nczAnnouncementsSubHeading}
					content={insightsGuides?.nczAnnouncements.nczAnnouncementsContent}
					backgroundColor={insightsGuides?.nczAnnouncements.backgroundColor}
					allCategory={allAnnouncementCategory}
					allPosts={announcementPost}
				/>
				<ArticleListing
					heading={insightsGuides?.industryInsights.industryInsightsHeading}
					tagline={insightsGuides?.industryInsights.industryInsightsSubHeading}
					content={insightsGuides?.industryInsights.industryInsightsContent}
					allPosts={industryInsightPosts}
				/>
				<ClientTestimonials
					testimonials={
						insightsGuides?.insightsAndGuidesTestimonials.selectTestimonials
							?.nodes
					}
					bgColor={
						insightsGuides?.insightsAndGuidesTestimonials.backgroundColor
					}
					textColor="#fff"
					starIconColor="#fff"
					wrapperClass="white-arrow"
				/>
				{/* <NczGuide
					heading={insightsGuides?.nczGuide.nczGuideHeading}
					tagline={insightsGuides?.nczGuide.nczGuideSubHeading}
					content={insightsGuides?.nczGuide.nczGuideContent}
					allPosts={nczGuidePosts}
				/> */}
				<StayUpToDate
					heading={
						insightsGuides?.stayUpToDateSection.stayUpToDateSectionHeading
					}
					content={
						insightsGuides?.stayUpToDateSection.stayUpToDateSectionContent
					}
					notice={insightsGuides?.stayUpToDateSection.stayUpToDateSectionNotice}
					image={
						insightsGuides?.stayUpToDateSection.stayUpToDateSectionImage?.node
					}
					imageBgColor={
						insightsGuides?.stayUpToDateSection.sectionImageBackgroundColor
					}
				/>
				<ValuableInsights
					heading={insightsGuides?.valuableInsights.valuableInsightsHeading}
					content={insightsGuides?.valuableInsights.valuableInsightsContent}
					btnCta1={insightsGuides?.valuableInsights.valuableInsightsButton1}
					trustedByHeading={insightsGuides?.valuableInsights.trustedByHeading}
					truestedByLogos={
						insightsGuides?.valuableInsights.selectTrustedByLogos?.nodes
					}
					bgColor={insightsGuides?.valuableInsights.backgroundColor}
					bgImage={
						insightsGuides?.valuableInsights.backgroundImage?.node.sourceUrl
					}
				/>
			</Layout>
		</>
	);
};

export default InsightAndGuidePage;
